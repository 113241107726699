<template>
  <div class="content-area">
    <hero-bar>
      {{ title }}
      <b-button
        slot="right"
        icon-left="content-save"
        type="is-link"
        :class="{ 'is-loading': isLoading }"
        @click="btnSubmit"
      >
        {{ $t('save') }}
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <card-component :title="$t('userForm')" icon="ballot">
        <form ref="form" @submit.prevent="submit">
          <b-field horizontal>
            <b-field>
              <b-input
                v-model="form.name"
                icon="account"
                :placeholder="$t('fullName')"
                name="name"
              />
            </b-field>
            <b-field>
              <b-input
                v-model="form.username"
                icon="account"
                type="text"
                :placeholder="$t('username')"
                name="username"
                required
              />
            </b-field>
          </b-field>
          <b-field horizontal>
            <b-field>
              <b-input
                v-model="form.email"
                icon="email"
                type="email"
                :placeholder="$t('email')"
                name="email"
              />
            </b-field>
          </b-field>
          <b-field horizontal>
            <b-field>
              <VuePhoneNumberInput
                ref="phonenumber"
                v-model="form.phonenumber"
                :default-country-code="phoneCountryCode"
                @update="phoneNumberUpdate"
              />
            </b-field>
          </b-field>
          <b-field horizontal>
            <b-field>
              <b-input
                v-model="form.password"
                icon="lock-question"
                type="password"
                :placeholder="$t('password')"
                name="password"
                :required="password_required"
              />
            </b-field>
            <b-field>
              <b-input
                id="confirm_password"
                ref="passwordconfirmation"
                v-model="form.password_confirmation"
                icon="lock-question"
                type="password"
                :placeholder="$t('passwordConfirmation')"
                name="password_confirmation"
                :required="password_required"
                @blur="checkPasswordMatch"
              />
            </b-field>
          </b-field>
          <hr>
          <b-field :label="$t('department')" horizontal>
            <b-select
              v-model="form.department"
              :placeholder="$t('selectDepartment')"
              required
            >
              <option
                v-for="(department, index) in departments"
                :key="'department' + index"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </b-select>
            <b-button
              icon-left="plus-circle"
              type="is-light"
              @click="isDepartmentModalActive = true"
            >
              {{ $t('addDepartment') }}
            </b-button>
          </b-field>
          <b-field :label="$t('role')" horizontal>
            <b-select
              v-model="form.role_id"
              :placeholder="$t('selectRole')"
              required
            >
              <option
                v-for="(role, index) in roles"
                :key="'roles'+ index"
                :value="role.id"
              >
                {{ role.name }}
              </option>
            </b-select>
          </b-field>
          <b-field v-if="!isCreateMode" :label="$t('txtstatus')" horizontal>
            <b-switch v-model="form.status" type="is-purple" />
          </b-field>
        </form>
      </card-component>
      <UserAddresses :addresses="addresses" class="mt-4" />
      <b-modal :active.sync="isDepartmentModalActive">
        <form @submit.prevent="submitDepartmentForm">
          <div class="modal-card" style="width: auto;">
            <header class="modal-card-head">
              <p class="modal-card-title">
                {{ $t('createDepartment') }}
              </p>
            </header>
            <section class="modal-card-body">
              <b-field :label="$t('departmentName')">
                <b-input
                  v-model="department_form.name"
                  type="text"
                  :placeholder="$t('departmentName')"
                  autofocus
                  required
                />
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <button class="button" type="button" @click="isDepartmentModalActive = false">
                {{ $t('close') }}
              </button>
              <button class="button is-primary" :class="{'is-loading': isDepartmentLoading}">
                {{ $t('create') }}
              </button>
            </footer>
          </div>
        </form>
      </b-modal>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserAddresses from '@/components/UserAddresses'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

export default {
  title: ({ $i18n }) => $i18n.t('titles.createUser'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    VuePhoneNumberInput: () => import('vue-phone-number-input'),
    UserAddresses,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      isLoading: false,
      isDepartmentLoading: false,
      phoneCountryCode: 'TR',
      password_required: true,
      form: {
        name: null,
        username: null,
        email: null,
        phone: null,
        phonenumber: null,
        nationalNumber: null,
        password: null,
        password_confirmation: null,
        department: null,
        role_id: null,
        status: true,
      },
      department_form: {
        name: null,
        id: null,
        is_new: false,
      },
      isDepartmentModalActive: false,
      addresses: [],
    }
  },
  computed: {
    ...mapState([
      'roles',
      'departments',
    ]),
    isCreateMode () {
      return !this.id
    },
    title () {
      return this.isCreateMode ? this.$t('createUser') : this.$t('editUser')
    },
    successMessage () {
      return this.isCreateMode ? this.$t('created') : this.$t('updated')
    },
  },
  watch: {
    'form.password' () {
      if (!this.isCreateMode && this.form.password) {
        this.password_required = true
      }

      if (!this.isCreateMode && (this.form.password == null || this.form.password == '')) {
        this.password_required = false
      }
    },
  },
  beforeDestroy () {
    this.$store.commit('setAsideActiveMenuKey', '')
  },
  mounted () {
    if (this.roles.length == 0) {
      this.$store.dispatch('getRoles')
      this.$store.dispatch('getDepartments')
    }

    this.$store.commit('setAsideActiveMenuKey', 'users.index')

    if (!this.isCreateMode) {
      document.title = this.title

      this.showSpinner()

      this.$http.get('users/' + this.id).then(({ data }) => {
        let userAttr = data.data.attributes
        this.form.name = userAttr.name
        this.form.username = userAttr.username
        this.form.email = userAttr.email
        this.form.phone = userAttr.phone
        this.form.phonenumber = userAttr.phone
        this.form.department = userAttr.department_id
        this.form.role_id = userAttr.role_id
        this.form.status = userAttr.status

        if (userAttr.phone) {
          this.formatPhoneNumber(userAttr.phone)
        }

        this.password_required = false
      }).catch(() => {
        this.$buefy.toast.open({
          message: this.$t('error'),
          type: 'is-warning',
        })

        this.$router.back()
      }).finally(() => this.hideSpinner())

      this.$http.get(`users/${this.id}/addresses`).then(({ data }) => {
        this.addresses = data.data
      }).catch(() => {
        this.addresses = []
      })
    }
  },
  methods: {
    formatPhoneNumber (number) {
      const asYouType = new AsYouType()
      asYouType.input(number)

      if (asYouType.getNumber()) {
        const phoneNumber = parsePhoneNumberFromString(asYouType.getNumber().number, asYouType.getNumber().country)
        this.form.phonenumber = phoneNumber.formatNational()
        this.phoneCountryCode = asYouType.getNumber().country
      }
    },
    phoneNumberUpdate (data) {
      this.form.phone = data.e164
    },
    checkPasswordMatch () {
      if (this.form.password !== this.form.password_confirmation) {
        document.getElementById('confirm_password').setCustomValidity('Passwords don\'t match.')
      } else {
        document.getElementById('confirm_password').setCustomValidity('')
      }
    },
    btnSubmit () {
      if (this.$refs.form.checkValidity()) {
        this.submit()
      } else {
        this.$refs.form.reportValidity()
      }
    },
    submitDepartmentForm () {
      this.isDepartmentLoading = true

      this.$http.post('departments', {
        name: this.department_form.name
      }).then(({ data }) => {
        if (data.success !== true) {
          this.$buefy.toast.open({
            message: data.message,
            type: 'is-warning',
          })

          return
        }

        this.$buefy.toast.open({
          message: this.$t('created'),
          type: 'is-success',
        })

        this.department_form.id = data.id
        this.department_form.name = null
        this.isDepartmentModalActive = false

        this.$store.dispatch('getDepartments').then(() => {
          this.form.department = this.department_form.id
        })
      }).catch(() => {
        this.$buefy.toast.open({
          message: this.$t('error'),
          type: 'is-warning',
        })
      }).finally(() => this.isDepartmentLoading = false)
    },
    submit () {
      this.isLoading = true

      const url = this.isCreateMode ? 'users' : 'users/' + this.id
      const method = this.isCreateMode ? 'POST' : 'PUT'

      const data = {
        id: this.id,
        name: this.form.name,
        username: this.form.username,
        email: this.form.email,
        phone: this.form.phone,
        department_id: this.form.department,
        role_id: this.form.role_id,
        password: this.form.password,
        status: this.form.status,
        password_confirmation: this.form.password_confirmation,
      }

      this.$http({ url, method, data }).then(({ data }) => {
        if (data.success) {
          this.$buefy.toast.open({
            message: this.successMessage,
            type: 'is-success',
            queue: false,
          })

          this.$router.go(-1)
        }
      }).catch(e => {
        const errors = this.$_.flattenDeep(this.$_.values(e.response.data.errors)).join('<br>')

        this.$buefy.toast.open({
          message: `${this.$t('error')} : <br> ${errors}`,
          type: 'is-warning',
          duration: 5000,
        })
      }).finally(() => this.isLoading = false)
    },
  },
}
</script>
